import "./src/styles/global.css"

window.axeptioSettings = {
  clientId: '66b105e8a67b1082144facf1',
  cookiesVersion: 'céline gau - sophrologue-fr-EU',
  googleConsentMode: {
    default: {
      analytics_storage: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      wait_for_update: 500,
    },
  },
}
 
;(function (d, s) {
  var t = d.getElementsByTagName(s)[0],
    e = d.createElement(s)
  e.async = true
  e.src = '//static.axept.io/sdk.js'
  t.parentNode.insertBefore(e, t)
})(document, 'script')